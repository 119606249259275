import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { uploadVideo } from "../../../Actions/consts";

// объявляем наш контекст
let VideoUploadContext = {
  progress: 0,
  loading: false,
  isReady: false,
};
let { Provider } = (VideoUploadContext = createContext(null));

let VideoUploadProvider = ({ children }) => {
  const [uploadVideoId, setUploadVideoId] = useState(null);
  let [videoUploadState, setVideoUploadState] = React.useState({
    progress: 0,
    loading: false,
    isReady: false,
  });

  const onVideoUpload = (e, id) => {
    setUploadVideoId(id);
    const file = e.target.files[0];
    setVideoUploadState({ ...videoUploadState, loading: true, isReady: false });
    const config = {
      onUploadProgress: (progressEvent) => {
        let { progress } = videoUploadState;
        progress = (progressEvent.loaded / progressEvent.total) * 100;
        setVideoUploadState({
          ...videoUploadState,
          progress,
          loading: true,
          isReady: false,
        });
      },
    };

    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${uploadVideo}/${id}`, formData, config)
      .then((res) => {
        localStorage.setItem("uploadVideo", id);
        setVideoUploadState({
          ...videoUploadState,
          loading: false,
          isReady: true,
        });
      })
      .catch((err) => {
        console.log("error: ", err.message);
      });
  };

  return (
    <Provider
      value={{
        videoUploadState,
        setVideoUploadState,
        onVideoUpload,
        uploadVideoId,
      }}
    >
      {children}
    </Provider>
  );
};

export { VideoUploadContext, VideoUploadProvider };
