// export const baseUrl = "https://api.atips.io";
// export const cinemaUrl = "https://api.atips.media";
// export const marketUrl = "https://api.market.atips.io";
// export const bloggerUrl = "https://api.creator.atips.io";
// export const chatUrl =  "https://api.advertising.atips.io";
// export const chatDomen = "api.advertising.atips.io";
// export const graphqlDomen = "https://stat.atips.io/graphql";
// export const constructorLink = "https://atips.io/bconstructor";
// export const updateCategoryUrl = "https://atips.io/pregenerate/index.php";
// export const creatorCategories = "https://atips.io/pregenerate/getextra/";
// export const updateVideoCategories = "https://atips.io/filmdata/videocategoriesprod/";

console.log(process.env.ENVIRONMENT, process.env.NODE_ENV);

export const baseUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://api.atips.io"
    : "https://api.dev.testatips.ru";
export const cinemaUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://api.atips.media"
    : "https://dev.api.atips.media";
export const marketUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://api.market.atips.io"
    : "https://dev.api.market.atips.io";
export const bloggerUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://api.creator.atips.io"
    : "https://dev.api.creator.atips.io";
export const chatUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://api.advertising.atips.io"
    : "https://dev.api.advertising.atips.io";
export const chatDomen =
  process.env.ENVIRONMENT === "production"
    ? "api.advertising.atips.io"
    : "dev.api.advertising.atips.io";
export const graphqlDomen =
  process.env.ENVIRONMENT === "production"
    ? "https://stat.atips.io/graphql"
    : "https://devstat.testatips.ru/graphql";
export const constructorLink =
  process.env.ENVIRONMENT === "production"
    ? "https://atips.io/bconstructor"
    : "https://bconstructor.testatips.ru";
export const updateCategoryUrl =
  process.env.ENVIRONMENT === "production"
    ? "https://atips.io/pregenerate/index.php"
    : "https://atips.io/pregenerate/indexdev.php";
export const creatorCategories =
  process.env.ENVIRONMENT === "production"
    ? "https://atips.io/pregenerate/getextra/"
    : "https://atips.io/pregenerate/getextradev/";
export const updateVideoCategories =
  process.env.ENVIRONMENT === "production"
    ? "https://atips.io/filmdata/videocategoriesprod/"
    : "https://atips.io/filmdata/videocategories/";

export const uploadVideo =
  process.env.ENVIRONMENT === "production"
    ? "https://media.atips.io:5056/uploadmedia"
    : "https://media.atips.io:5055/uploadmedia";
